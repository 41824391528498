import React, {useEffect, useState} from "react";
import {useWallet} from "@solana/wallet-adapter-react";

export default function MainContent({mints, nftsOnSale}) {
    const wallet = useWallet();
    const [usersMints, setUsersMints] = useState([]);
    const [usersNftsOnSale, setUsersNftsOnSale] = useState([]);

    useEffect(async () => {
        if (mints.length)
        {
            setUsersMints(mints);
        }

        setUsersNftsOnSale(nftsOnSale);
    }, [mints, nftsOnSale, wallet]);

    return(
        <div className="wrap content page">
            <div className="title">
                <h1>Find out how rare your Solana NFTs are</h1>
                {/* <div class="stats">
                    <div class="stat">
                        <span>118</span>collections
                    </div>
                    <div class="stat">
                        <span>1.02M</span>items
                    </div>
                    <div class="stat">
                        <span>◎800.5k</span>volume
                    </div>
                </div> */}
            </div>

            <div className="featured">
                <h2><img src="/img/lightning.svg" alt="img" /> My portfolio</h2>
                <div className="featured_items">
                    {
                        usersMints && usersMints.map((mint, i) => {
                            const image = mint.metadata.image;
                            console.log('IMAGE: ', image);
                            let mint_address = "item/" + mint.mint;
                            const k = 'img' + i;
                            return (
                                <div key={i} className="featured_item">
                                    <div className="featured_item_img">
                                        <a href={mint_address}>
                                            <img key={k} src={image} alt="img" />
                                        </a>
                                    </div>
                                    <div className="featured_image_desc">
                                        <h3>{mint.metadata.name}</h3>
                                        <div className="item_stats">
                                            <div className="item_stat">
                                                floor price<span>◎34</span>
                                            </div>
                                            <div className="item_stat">
                                                volume<span>2.04k</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            <div className="featured">
                <h2><img src="/img/lightning.svg" alt="img" /> NFTs on sale</h2>
                <div className="featured_items">
                    {
                        usersNftsOnSale && usersNftsOnSale.map((mint, i) => {
                            let image = mint.metadata.image;
                            let mint_address = "item/" + mint.mint;
                            const key = "nft" + i;
                            return (
                                <div key={key} className="featured_item">
                                    <div className="featured_item_img">
                                        <a href={mint_address}>
                                            <img src={image} alt="img" />
                                        </a>
                                    </div>
                                    <div className="featured_image_desc">
                                        <h3>{ mint.metadata.name }</h3>
                                        <div className="item_stats">
                                            <div className="item_stat">
                                                floor price<span>◎34</span>
                                            </div>
                                            <div className="item_stat">
                                                volume<span>2.04k</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            <div className="featured">
                <h2><img src="img/lightning.svg" alt="img" /> Featured</h2>
                <div className="featured_items">
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey Business</h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey Business</h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey Business</h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey Business</h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey </h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey Business</h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey Business</h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="featured_item">
                        <div className="featured_item_img">
                            <a href="#">
                                <img src="https://picsum.photos/id/1053/288/288" alt="img" />
                            </a>
                        </div>
                        <div className="featured_image_desc">
                            <h3>Solana Monkey Business</h3>
                            <div className="item_stats">
                                <div className="item_stat">
                                    floor price<span>◎34</span>
                                </div>
                                <div className="item_stat">
                                    volume<span>2.04k</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="all_collections_wrap">

                <div className="holder">
                    <h2>All collections</h2>

                    <div className="holder_right">
                        <div>
                            <select>
                                <option>By type</option>
                                <option>By price</option>
                                <option>By rank</option>
                                <option>By volume</option>
                            </select>
                        </div>
                    </div>

                    <div className="stats_holder">
                        <div className="stats">
                            <div className="stat">
                                <span>118</span>collections
                            </div>
                            <div className="stat">
                                <span>1.02M</span>items
                            </div>
                            <div className="stat">
                                <span>◎800.5k</span>volume
                            </div>
                        </div>
                    </div>
                </div>


                <div className="all_collections">
                    <div className="all_coll_row">
                        <div className="all_coll_col">
                            <a href="#">name</a>
                        </div>
                        <div className="all_coll_col">
                            <a href="#">floor price</a>
                        </div>
                        <div className="all_coll_col">
                            <a href="#">items</a>
                        </div>
                        <div className="all_coll_col">
                            <a href="#">holders</a>
                        </div>
                        <div className="all_coll_col">
                            <a href="#">on sale</a>
                        </div>
                        <div className="all_coll_col">
                            <a href="#">% on sale</a>
                        </div>
                        <div className="all_coll_col"><span className="sort_table">floor mc (usd)</span></div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey Business (SMB)</span></a>
                        </div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey</span></a></div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey Business (SMB)</span></a>
                        </div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey</span></a></div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey Business (SMB)</span></a>
                        </div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey</span></a></div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey Business (SMB)</span></a>
                        </div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey</span></a></div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey Business (SMB)</span></a>
                        </div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>

                    <div className="all_coll_row">
                        <div className="all_coll_col"><a href="#"><img src="https://picsum.photos/id/1053/56/56"
                                                                       alt="img" /><span>Solana Monkey</span></a></div>
                        <div className="all_coll_col">184</div>
                        <div className="all_coll_col">5000</div>
                        <div className="all_coll_col">2231</div>
                        <div className="all_coll_col">654</div>
                        <div className="all_coll_col">12.24</div>
                        <div className="all_coll_col">225.43M</div>
                    </div>
                </div>
            </div>
        </div>
    );
}