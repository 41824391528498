import React from "react";
import {WalletMultiButton} from "@solana/wallet-adapter-material-ui";

function Header() {

    return (
        <div className="header">
            <div className="wrap">
                <div className="trigger"><span></span></div>
                <div className="logo_holder">
                    <div className="logo">
                        <a href="/"><img className="dark_logo" src="/img/logo_dark.svg" alt="img" /><img
                            className="light_logo" src="/img/logo_light.svg" alt="img" /></a>
                    </div>
                    <a href="#" className="butt"><img src="/img/flame-small.svg" alt="img" /> upcoming drops</a>
                </div>
                <div className="search">
                    <form>
                        <input type="text" name="country" id="autocomplete-ajax" className="header1" placeholder="Search by name, wallet or ID"/>
                        <input type="text" name="country" id="autocomplete-ajax-x" disabled="disabled" className="header2"/>
                        <input type="submit" />
                            { /*<input type="text" id="search" autocomplete="off" placeholder="Search by name, wallet or ID"> */}
                    </form>
                </div>
                <div className="header_right">
                    <div className="social">
                        <a href="#" className="faq"></a>
                        <a href="#" className="twitter"></a>
                        <a href="#" className="discord"></a>
                        <a href="#" className="daynight"></a>
                    </div>
                    {/*<a href="#" className="butt">
                        <img src="/img/wallet.svg" alt="img" />
                    </a>*/}
                    <div className="tempButt">
                        <WalletMultiButton />
                    </div>



                </div>
            </div>
        </div>
    );
}

export default Header;