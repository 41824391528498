import { PublicKey, clusterApiUrl } from "@solana/web3.js";
import { getPhantomWallet } from "@solana/wallet-adapter-wallets";

export const preflightCommitment = "confirmed";
export const programID = 'GVFf9rk5KWohXibuenxgRx9VHybTUEzsEG2UjcfFocFb'; // idl.metadata.address);
export const wallets = [getPhantomWallet()];
export const PROGRAM_IDS = {
    auction: programID
};
// export const NFT_ADDR = new PublicKey('XjaMGxSzpzEgJ5h3GaxX8LSCXQLQ1qL8Kkd1T9j9VHU');
export const AUCTION_ORGANIZER_TREASURY = new PublicKey('FQFjL9VGuGP5H8wzX7rbwm7LYE2Wmrz9wAwp5WFYCBH1');
export const CURL_PATH = 'https://fbm.timonix.com/curl/curl.php';
// 'http://localhost:3000/curl/curl.php';

const localnet = "http://127.0.0.1:8899";
const devnet = clusterApiUrl("devnet");
// const mainnet = clusterApiUrl("mainnet-beta");
export const network = devnet;


