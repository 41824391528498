import React, { useCallback, useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Link
} from "react-router-dom";
import {
  ConnectionProvider, useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { SnackbarProvider, useSnackbar } from "notistack";
import { createTheme, ThemeProvider } from "@material-ui/core";
import {blue, grey, orange} from "@material-ui/core/colors";
// import { Program, Provider, web3, BN } from "@project-serum/anchor";
// import Main from "./components/Main";
import {PROGRAM_IDS, network, wallets, NFT_ADDR, SELLER_ADDR, preflightCommitment} from "./utils/config";
// import { toPublicKey } from "./common/src/utils/functions";
// import { Connection, PublicKey } from "@solana/web3.js";
import Home from './containers/Home/index';
import Item from './containers/Item/index';


const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(0,0,0,0.56)",
    },
    secondary: {
      main: orange[300],
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
        border: "1px solid #424242",
        width: "140px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "uppercase",
        lineHeight: "48px",
        padding: "0 20px",
        fontSize: "16px",
        fontWeight: 500,
        width: 200
      },
      startIcon: {
        marginRight: 10, // margin: -2px 3px 2px 0;
      },
      endIcon: {
        marginLeft: -10
      },
      label: {
        color: "rgba(255,255,255,0.56)",

      }
    },
    MuiLink: {
      root: {
        color: "initial",
      },
    },
  },
});

// Nest app within <SnackbarProvider /> so that we can set up Snackbar notifications on Wallet errors
function AppWrappedWithProviders() {
  const { enqueueSnackbar } = useSnackbar();
  const wallet = useWallet();
  //
  // const [voteAccount, setVoteAccount] = useState({
  //   account: null,
  //   accountBump: null,
  // });
  //
  // useEffect(async () => {
  //   const getVoteAccount = async () => {
  //     const connection = new Connection(network, preflightCommitment);
  //     const provider = new Provider(connection, wallet, preflightCommitment);
  //
  //     let auth = toPublicKey('3wa9t1VamQZe8P1esSXbs89nhLr7FUvfH3jZanrSswFC');
  //     let [_auction, _auctionBump] = await PublicKey.findProgramAddress(
  //         ['auction_nft', NFT_ADDR.toBuffer(), auth.toBuffer()],
  //         toPublicKey(PROGRAM_IDS.auction)
  //     );
  //     let auction = _auction;
  //     let auctionBump = _auctionBump;
  //     // console.log('Auction key: ', _auction.toString());
  //     setVoteAccount({ account: auction, accountBump: auctionBump });
  //   };
  //   await getVoteAccount();
  // }, []);

  const onWalletError = useCallback(
    (error) => {
      enqueueSnackbar(
        error.message ? `${error.name}: ${error.message}` : error.name,
        { variant: "error" }
      );
      console.error(error);
    },
    [enqueueSnackbar]
  );

  // Wrap <Main /> within <WalletProvider /> so that we can access useWallet hook within Main
  return (
    <WalletProvider wallets={wallets} onError={onWalletError} autoConnect>
      <WalletDialogProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/item/:item" element={<Item auctionAccount={null} network={network} />} />
          </Routes>
        </BrowserRouter>
        {/*
        <Home />
        <Main
          network={network}
          auctionAccount={voteAccount.account}
          voteAccountBump={voteAccount.accountBump}
          NFT_ADDR={NFT_ADDR}
        />*/}
      </WalletDialogProvider>
    </WalletProvider>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <ConnectionProvider endpoint={network}>
          <AppWrappedWithProviders />
        </ConnectionProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
