import React, {useEffect, useState} from "react";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import MainContent from "../MainContent/index";
import { Connection, PublicKey } from "@solana/web3.js";
import { preflightCommitment, PROGRAM_IDS, network } from "../../utils/config";
import {Provider} from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import idl from "../../idl.json";
import { useWallet } from "@solana/wallet-adapter-react";
import * as SPLToken from "@solana/spl-token";
const { getMeta } = require('../../utils/get-nft-metadata');

const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');

function Home() {
    const wallet = useWallet();

    const connection = new Connection(network, preflightCommitment);
    const provider = new Provider(connection, wallet, {preflightCommitment});
    // const program = new anchor.Program(idl, new PublicKey(PROGRAM_IDS.auction), provider);

    const [userMints, setUserMints] = useState([]);
    const [nftsOnSale, setNftsOnSale] = useState([]);
    const [loaded, setLoaded] = useState(false);

    let mints = [];
    let saleMintsArray = [];


    // const [, updateState] = React.useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(async () => {
        const getNfts = async (accountPublicKey) => {
            const ownerAddress = new PublicKey(accountPublicKey);
            let response = await connection.getTokenAccountsByOwner(
                ownerAddress, // owner here
                {
                    programId: TOKEN_PROGRAM_ID,
                }
            );

            const requests = response.value.map(async (e, i) => {
                const accountInfo = SPLToken.AccountLayout.decode(e.account.data);

                if (SPLToken.u64.fromBuffer(accountInfo.amount) == '1')
                {
                    // console.log(`pubkey: ${e.pubkey.toBase58()}`);
                    // console.log(`mint: ${new PublicKey(accountInfo.mint)}`);
                    // console.log(`amount: ${SPLToken.u64.fromBuffer(accountInfo.amount)}`);

                    // let token = new SPLToken.Token(connection, new PublicKey(accountInfo.mint), TOKEN_PROGRAM_ID, null);
                    // let tokenInfo = await token.getMintInfo();
                    // console.log(tokenInfo);
                    console.log(accountInfo.mint);
                    const metaDataObj = await getMeta(accountInfo.mint, network);
                    console.log("userMints metaDataObj: ", metaDataObj);
                    // setUserMints(metaDataObj);
                    // console.log('metaDataObj mint: ', (new PublicKey(accountInfo.mint)).toString());
                    // console.log('metaDataObj: ', metaDataObj);
                    // console.log('metaDataObj mint sale 1: ', metaDataObj);

                    if (typeof metaDataObj != 'undefined')
                    {
                        if (typeof metaDataObj.metadata != "undefined")
                        {
                            mints.push({
                                mint: new PublicKey(accountInfo.mint),
                                metadata: metaDataObj.metadata
                            });
                        }
                    }
                }
            });

            // Wait for all requests, and then setState
            return Promise.all(requests).then(() => {
                setUserMints(mints);
                console.log("userMints: ", userMints);
            });
        }

        const getNftsOnSale = async () => {
            let saleMints = [
                'AoqMUr4XbKB3HbrZKeyCiEQrNoYqNi9Jdx82SmUGhkgU',
                '8bcrB4vaGmoCLhARNErTCNmmsfV25rEXtTnwp7evMDvy',
                'ENBAxg1NH1nUp3FqgJQ2dudxZea9r7je644dHRzQjqye'
            ];

            const requests = saleMints.map(async (mint, i) => {
                const metaDataObj = await getMeta(mint, network);

                // console.log('metaDataObj mint sale 2: ', metaDataObj);
                if (typeof metaDataObj != "undefined") {
                    if (typeof metaDataObj.metadata != "undefined") {
                        saleMintsArray.push({
                            mint: new PublicKey(mint),
                            metadata: metaDataObj.metadata
                        });
                    }
                }
                console.log('1: ' + mint);
                console.log('2: ' + metaDataObj.metadata);
            });

            // Wait for all requests, and then setState
            return Promise.all(requests).then(() => {
                setNftsOnSale(saleMintsArray);
                console.log('saleMintsArray: ', nftsOnSale);
                setLoaded(true);
            });
        }

        if (wallet.connected)
        {
            await getNfts(wallet.publicKey);
            await getNftsOnSale();
        }
    }, [wallet, network]);


    return (
        <>
            <Header />
            <MainContent mints={userMints} nftsOnSale={nftsOnSale} />
            <Footer />
        </>
    );
}

export default Home;
