import React, { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import {
    Connection,
    LAMPORTS_PER_SOL,
    PublicKey,
    SystemProgram,
    SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import { Provider, BN } from "@project-serum/anchor";
import { Box, Button, Container, Grid } from "@material-ui/core";
import {PROGRAM_IDS, preflightCommitment, AUCTION_ORGANIZER_TREASURY, network} from "../../utils/config";
import { toPublicKey, pubkeyToString, findProgramAddress } from '../../common/src/utils/functions';
import * as BufferLayout from 'buffer-layout';

import idl from "../../idl.json";
import VoteOption from "../../components/VoteOption";
import Intro from "../../components/Intro";
import VoteHistory from "../../components/VoteHistory";
import { decodeMetadata, Metadata } from '../../utils/schema';
import * as borsh from 'borsh';
import * as anchor from "@project-serum/anchor";
import assert from "assert";
import { getMetadata } from "../../utils/helpers";
import Header from "../../components/header/index";
import Footer from "../../components/footer/index";
import {getMeta} from "../../utils/get-nft-metadata";
import {makeStyles} from "@material-ui/styles";
import {green} from "@material-ui/core/colors";
import { getHolders } from "../../utils/get-holders";

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        "&.hidden": {
            visibility: "hidden",
        },
    },
    connected: {
        color: green[500],
    },
    connectedBubble: {
        backgroundColor: green[500],
        height: 12,
        width: 12,
        borderRadius: "50%",
        marginRight: theme.spacing(0.5),
    },
    title: {
        fontWeight: 700,
    },
}));

const propTypes = {};

const defaultProps = {};

class Assignable {
    constructor(properties) {
        Object.keys(properties).map((key) => {
            return (this[key] = properties[key]);
        });
    }
}

class Task extends Assignable {}

const TOKEN_PROGRAM_ID = new anchor.web3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
const METADATA_PROGRAM_ID = new PublicKey(
    "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

const PREFIX = "auction_nft";
const NFT_TREASURY = "nft_treasury";
const TREASURY = "currency_treasury";
const SIGNER = "signer";
const BIDDER_NFT = "bidder_nft_holder";
const METADATA = "metadata";

const nftKey = window.location.pathname ? window.location.pathname.substr(6,44) : '11111111111111111111111111111111';

let auctionAccount;

export default function Item({ network }) {
    const wallet = useWallet();
    const classes = useStyles();

    const [initialized, setInitialized] = useState({
        auctionPubkey: null,
        initialized: false
    });
    const [canCreateAuction, setCanCreateAuction] = useState(false);
    const [canAcceptBid, setCanAcceptBid] = useState(false);
    const [canBid, setCanBid] = useState(false);
    const [auctionTxHistory, setAuctionTxHistory] = useState([]);
    const [nftImage, setNftImage] = useState(null);
    const [nftBuyPrice, setNftBuyPrice] = useState(0);
    const [lastBidPrice, setLastBidPrice] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const NFT_ADDR = new PublicKey(nftKey);

    let auctionAccountData = {};

    useEffect( async () => {
        if (nftKey == null)
        {
            return;
        }
        const metaDataObj = await getMeta(NFT_ADDR, network);
        console.log('metaDataObj: ', metaDataObj);
        if (!metaDataObj.metadata.image)
        {
            return;
        }
        setNftImage(metaDataObj.metadata.image);

        let [_auction, _auctionBump] = await PublicKey.findProgramAddress(
            ['auction_nft', NFT_ADDR.toBuffer()],
            toPublicKey(PROGRAM_IDS.auction)
        );
        // console.log('_auction: ', _auction.toString());
        auctionAccount = _auction;

        let calledOnce = false;
        const getInitialized = async() => {
            if (calledOnce) {
                return;
            }
            // console.log('auctionAccount use effect: ', auctionAccount.toString());

            const connection = new Connection(network, preflightCommitment);
            const provider = new Provider(connection, wallet, preflightCommitment);
            const program = new anchor.Program(idl, new PublicKey(PROGRAM_IDS.auction), provider);

            try {
                let acc = auctionAccount.toString();
                console.log('get auction data: ', acc);
                auctionAccountData = await program.account.auction.fetch(auctionAccount);
                console.log('auctionAccountData: ', auctionAccountData);
                console.log('wallet.publicKey.toString(): ', wallet.publicKey.toString());
                console.log('auctionAccountData.seller.toString(): ', auctionAccountData.seller.toString());

                if (auctionAccountData.seller.toString() === wallet.publicKey.toString()) {
                    setCanCreateAuction(true);
                }

                if (!!auctionAccountData.ongoing)
                {
                    setInitialized({
                        auctionPubkey: auctionAccountData.auction,
                        initialized: true
                    });

                    const buyPrice = (auctionAccountData.buyPrice/LAMPORTS_PER_SOL).toString();
                    console.log('auctionAccountData.buy_price: ', buyPrice);
                    setNftBuyPrice(buyPrice);

                    const lastBidPriceAmount = (auctionAccountData.price/LAMPORTS_PER_SOL).toString();
                    setLastBidPrice(lastBidPriceAmount);

                    console.log('auctionAccountData.seller.toString(): ', auctionAccountData.seller.toString());
                    // console.log('provider.wallet.publicKey.toString(): ', wallet.publicKey.toString());
                    // console.log('ppppp: ', auctionAccountData.bidder.toString());
                    if (auctionAccountData.seller.toString() === wallet.publicKey.toString() && auctionAccountData.bidder.toString() != wallet.publicKey.toString())
                    {
                        setCanAcceptBid(true);
                    } else {
                        setCanBid(true);
                    }
                    console.log('Update bid list');
                    await updateBidList();
                    setLoaded(true);
                }
            } catch (e) {
                setCanAcceptBid(false);
                setCanBid(false);
                const holder = await getHolders(nftKey, network);
                setCanCreateAuction(false);
                if (typeof wallet.publicKey != "undefined")
                {
                    const user = wallet.publicKey;
                    console.log(holder);
                    if (typeof holder[user] !== "undefined" && holder[user].amount) {
                        setCanCreateAuction(true);
                    }
                }
                setLoaded(true);
                // console.log('Auction is not created', e);
            }
        }

        await getInitialized();
        calledOnce = true;
    }, [wallet]);

    /**
     * Create auction
     *
     * @param event
     * @returns {Promise<void>}
     */
    const createAuction = async (amount) => {
        /*
        Auction id:  FvxcquePShW8R4FS2ULYStufMronkFW1WknPpy3T8ymL
        Main.jsx:181 NFT:  CCdCKPbAZUnAAfHff9zoMQLcieAGmcAi4S8FdCiU4LUi
        Main.jsx:183 sellerTokenAccount:  DcXXQsf8jz1BiqRVw6HTDcg6WLoK9XczHzFwKvZMjMNP
        Main.jsx:197 NFT treasury:  2oshHoae4FF2rQbJgf4QLEW9QruZiMQC4WGgBcBFgS5V
        Main.jsx:211 currencyHolderPubkey:  C5SnuSWSDvpoc6f95C43vdNSaqLje7LEvBt4uVkfkuXa
        Main.jsx:215 treasuryMint:  So11111111111111111111111111111111111111112
        Main.jsx:216 SYSVAR_RENT_PUBKEY:  SysvarRent111111111111111111111111111111111
        Main.jsx:217 TOKEN_PROGRAM_ID:  TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA
        Main.jsx:218 SystemProgram.programId:  11111111111111111111111111111111
        Main.jsx:219 Seller:  3wa9t1VamQZe8P1esSXbs89nhLr7FUvfH3jZanrSswFC
        Main.jsx:250 auctionAccountInfo.bidder:  3wa9t1VamQZe8P1esSXbs89nhLr7FUvfH3jZanrSswFC
        Main.jsx:251 auctionAccountInfo.currency_holder_pubkey:  C5SnuSWSDvpoc6f95C43vdNSaqLje7LEvBt4uVkfkuXa
        Main.jsx:252 auctionAccountInfo.nft_holder_pubkey:  2oshHoae4FF2rQbJgf4QLEW9QruZiMQC4WGgBcBFgS5V
        Main.jsx:253 auctionAccountInfo Price:  500000000
        Main.jsx:254 auctionAccountInfo.seller:  3wa9t1VamQZe8P1esSXbs89nhLr7FUvfH3jZanrSswFC
        */

        const auctionInitPrice = amount * LAMPORTS_PER_SOL;
        console.log('auctionInitPrice: ', auctionInitPrice);
        const connection = new Connection(network, preflightCommitment);
        const provider = new anchor.Provider(connection, wallet, preflightCommitment);
        const program = new anchor.Program(idl, new PublicKey(PROGRAM_IDS.auction), provider);

        if (!wallet.connected)
        {
            console.log('Waiting for wallet connection');
            return;
        }

        const walletPubKey = provider.wallet.publicKey;

        let auction;
        let auctionBump;
        let [_auction, _auctionBump] = await anchor.web3.PublicKey.findProgramAddress(
            [PREFIX, NFT_ADDR.toBuffer()], // , walletPubKey.toBuffer()
            new PublicKey(PROGRAM_IDS.auction)
        );
        auction = _auction;
        auctionBump = _auctionBump;
        console.log('Auction id: ', auction.toString());

        const nftMintClient = NFT_ADDR;
        console.log('NFT: ', nftMintClient.toString());
        const sellerTokenAccount = (await connection.getTokenLargestAccounts(nftMintClient)).value[0].address;
        console.log('sellerTokenAccount: ', sellerTokenAccount.toString());

        const [_nftHolderPubkey, _nftHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                _auction.toBuffer(),
                NFT_TREASURY
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        let nftHolderPubkey = _nftHolderPubkey;
        let nftHolderBump = _nftHolderBump;

        console.log('NFT treasury: ', nftHolderPubkey.toString());

        const [_currencyHolderPubkey,  _currencyHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                _auction.toBuffer(),
                TREASURY,
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        let currencyHolderPubkey = _currencyHolderPubkey;
        let currencyHolderBump = _currencyHolderBump;

        console.log('currencyHolderPubkey: ', currencyHolderPubkey.toString());

        const NATIVE_SOL_MINT = new PublicKey('So11111111111111111111111111111111111111112');

        console.log('treasuryMint: ', NATIVE_SOL_MINT.toString());
        console.log('SYSVAR_RENT_PUBKEY: ', SYSVAR_RENT_PUBKEY.toString());
        console.log('TOKEN_PROGRAM_ID: ', TOKEN_PROGRAM_ID.toString());
        console.log('SystemProgram.programId: ', SystemProgram.programId.toString());
        console.log('Seller: ', walletPubKey.toString());

        try {
            let txSig = await program.rpc.createAuction(
                new BN(auctionInitPrice), auctionBump, nftHolderBump, currencyHolderBump, {
                    accounts: {
                        auction,
                        seller: walletPubKey,
                        sellerTokenAccount: NFT_ADDR,
                        sA: sellerTokenAccount,
                        sellerNftHolder: sellerTokenAccount,
                        nftHolderPubkey,
                        currencyHolderPubkey,
                        treasuryMint: NATIVE_SOL_MINT,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        rent: SYSVAR_RENT_PUBKEY,
                        systemProgram: SystemProgram.programId,
                    },
                    signers: []
                });

            // txSig.feePayer = walletPubKey;
            // txSig.recentBlockhash = (await provider.connection.getLatestBlockhash()).blockhash;
            // const signedTx = await provider.wallet.signTransaction(txSig);
            // console.log(signedTx);
            // const txId = await provider.connection.sendRawTransaction(signedTx.serialize());
            // await provider.connection.confirmTransaction(txId);
            // console.log('txId:', txId);
            //

            const auctionAccountInfo = await program.account.auction.fetch(auction);
            console.log('auctionAccountInfo.bidder: ', auctionAccountInfo.bidder.toString());
            console.log('auctionAccountInfo.currency_holder_pubkey: ', auctionAccountInfo.currencyHolderPubkey.toString());
            console.log('auctionAccountInfo.nft_holder_pubkey: ', auctionAccountInfo.nftHolderPubkey.toString());
            console.log('auctionAccountInfo Price: ', auctionAccountInfo.price.toString());
            console.log('auctionAccountInfo.seller: ', auctionAccountInfo.seller.toString());

            setInitialized({
                auctionPubkey: auction.toString(),
                initialized: true
            });
            setCanAcceptBid(true);
            assert.ok(true);
        } catch (e) {
            console.log(e);
        }
    }

    const submitBid = async (event) => {
        event.preventDefault();

        // const auctionAccount = 'FBuYN3h2kDQ4joXbNhxr9S5pBN1tuZx1zh11kje6eQN8';
        const connection = new Connection(network, preflightCommitment);
        const provider = new anchor.Provider(connection, wallet, preflightCommitment);
        const program = new anchor.Program(idl, new PublicKey(PROGRAM_IDS.auction), provider);

        if (!wallet.connected)
        {
            console.log('Waiting for wallet connection');
            return;
        }

        const auction_key = new PublicKey(auctionAccount.toString());
        console.log('Auction key: ', auction_key.toString());

        const bidPrice = event.target[0].value * LAMPORTS_PER_SOL;
        console.log('bidPrice: ', bidPrice);

        const walletPubKey = provider.wallet.publicKey;
        console.log('walletPubKey: ', walletPubKey.toString());

        const auctionAccountInfo = await program.account.auction.fetch(auction_key);
        console.log('auctionAccountInfo: ', auctionAccountInfo);

        console.log('Auction price: ', (auctionAccountInfo.price).toString());

        if (auctionAccountInfo.price.toString() >= bidPrice.toString())
        {
            console.log('Bid offer can not be less than last bid');
            // alert('Bid offer can not be less than last bid');
            // return;
        }

        if (auctionAccountInfo.seller.toString() === walletPubKey.toString())
        {
            console.log('Seller can not bid on it\'s own item!');
            alert('You can not bid on your item');
            return;
        }

        let auction = new PublicKey(auctionAccount);

        const [_currencyHolderPubkey,  _currencyHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auction_key.toBuffer(),
                TREASURY,
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        let currencyHolderPubkey = _currencyHolderPubkey;
        let oriRefundReceiver = auctionAccountInfo.refundReceiver;

        console.log('auction: ', auction_key.toString());
        console.log('bidder: ', auctionAccountInfo.bidder.toString());
        console.log('currencyHolderPubkey: ', currencyHolderPubkey.toString());
        console.log('oriRefundReceiver: ', oriRefundReceiver.toString());

        const [_bidderNftHolder, _bidderNftHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                walletPubKey.toBuffer(),
                NFT_ADDR.toBuffer(),
                auction_key.toBuffer(),
                BIDDER_NFT,
                NFT_TREASURY
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        let bidderNftHolder = _bidderNftHolder;
        let bidderNftHolderBump = _bidderNftHolderBump;
        const sA = (await connection.getTokenLargestAccounts(NFT_ADDR)).value[0].address;

        console.log('bidderNftHolder: ', bidderNftHolder.toString());
        console.log('sellerToken: ', sA.toString());
        console.log('nftToAccept: ', NFT_ADDR.toString());

        let txSig = await program.rpc.bidForNft(
            new BN(bidPrice), bidderNftHolderBump, {
                accounts: {
                    auction: auction_key,
                    bidder: walletPubKey,
                    currencyHolderPubkey,
                    oriRefundReceiver,
                    bidderNftHolder,
                    sA,
                    sellerToken: NFT_ADDR,
                    nftToAccept: NFT_ADDR,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    rent: SYSVAR_RENT_PUBKEY,
                    systemProgram: SystemProgram.programId,
                },
                signers: []
            });
        console.log(txSig);

        let list = await updateBidList();
        setAuctionTxHistory((oldAuctionTxHistory) => [...oldAuctionTxHistory, list]);
    }

    const acceptBid = async () => {
        const connection = new Connection(network, preflightCommitment);
        const provider = new anchor.Provider(connection, wallet, preflightCommitment);
        const program = new anchor.Program(idl, new PublicKey(PROGRAM_IDS.auction), provider);

        if (!wallet.connected)
        {
            console.log('Waiting for wallet connection');
            return;
        }

        const walletPubKey = provider.wallet.publicKey;
        console.log('walletPubKey: ', walletPubKey.toString());
        console.log('auctionAccount: ', auctionAccount.toString());

        const auctionAccountInfo = await program.account.auction.fetch(auctionAccount.toString());
        console.log('auctionAccountInfo: ', auctionAccountInfo);
        console.log('auctionAccountInfo.bidder: ', auctionAccountInfo.bidder.toString());
        console.log('auctionAccountInfo.currency_holder_pubkey: ', auctionAccountInfo.currencyHolderPubkey.toString());
        console.log('auctionAccountInfo.nft_holder_pubkey: ', auctionAccountInfo.nftHolderPubkey.toString());
        console.log('auctionAccountInfo Price: ', auctionAccountInfo.price.toString());
        console.log('auctionAccountInfo.seller: ', auctionAccountInfo.seller.toString());
        if (auctionAccountInfo.seller.toString() != walletPubKey.toString())
        {
            console.log('Seller: ', auctionAccountInfo.seller.toString());
            console.log('Buyer can not accept bid on auction that he does not own!');
            alert('You can not accept bid of another person auction');
            return;
        }

        let auction = new PublicKey(auctionAccount);

        const [_currencyHolderPubkey,  _currencyHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auction.toBuffer(),
                TREASURY,
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        let currencyHolderPubkey = _currencyHolderPubkey;
        let lastBidder = new PublicKey(auctionAccountInfo.bidder);
        const sellerTokenAccount = (await connection.getTokenLargestAccounts(NFT_ADDR)).value[0].address;

        console.log('auction: ', auction.toString());
        console.log('auction seller: ', auctionAccountInfo.seller.toString());
        console.log('currencyHolderPubkey: ', currencyHolderPubkey.toString());
        console.log('sellerTokenAccount: ', sellerTokenAccount.toString());
        console.log('Bidder: ', auctionAccountInfo.bidder.toString());
        console.log('lastBidder: ', lastBidder.toString());

        // const [_bidderNftHolder, _bidderNftHolderBump] = await PublicKey.findProgramAddress(
        //     [
        //       PREFIX,
        //       lastBidder.toBuffer(),
        //       NFT_ADDR.toBuffer(),
        //       auction.toBuffer(),
        //       BIDDER_NFT,
        //       NFT_TREASURY
        //     ],
        //     new PublicKey(PROGRAM_IDS.auction),
        // );

        const [_bidderNftHolder, _bidderNftHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auctionAccountInfo.bidder.toBuffer(),
                NFT_ADDR.toBuffer(),
                auction.toBuffer(),
                BIDDER_NFT,
                NFT_TREASURY
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );


        let bidderNftHolder = _bidderNftHolder; // auctionAccountInfo.nftHolderPubkey;
        console.log('Bidder nftHolderPubkey: ', bidderNftHolder.toString());

        const sA = (await connection.getTokenLargestAccounts(NFT_ADDR)).value[0].address;

        const [_nftHolderPubkey, _nftHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auction.toBuffer(),
                NFT_TREASURY
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        const metadata = await getMetadata(NFT_ADDR);

        const metadataObj = await program.provider.connection.getAccountInfo(
            metadata,
        );
        const metadataDecoded = decodeMetadata(
            Buffer.from(metadataObj.data),
        );

        console.log('metadataDecoded: ', metadataDecoded);

        const remainingAccounts = [];

        for (let i = 0; i < metadataDecoded.data.creators.length; i++) {
            remainingAccounts.push({
                pubkey: new PublicKey(metadataDecoded.data.creators[i].address),
                isWritable: true,
                isSigner: false,
            });
        }

        console.log('_nftHolderPubkey from seed: ', _nftHolderPubkey.toString());
        console.log('nftHolderPubkey auction: ', auctionAccountInfo.nftHolderPubkey.toString());

        /*
        auction:  FhHYEtFPekvF5rXg9AnsPQM9SvZaWjNY7ncsf1vSsf6n
        Main.jsx:319 currencyHolderPubkey:  37DqFz4aKvH3R2aaY2orACvkHZhZyvgbKZug2f9XSGFg
        Main.jsx:320 oriRefundReceiver:  2Ni4a2dA2TWR3Km91TaAk6Fdc5uKS9DzduqAfg7pKa99
        Main.jsx:338 bidderNftHolder:  5Ljd1omhmdcMptSoKcuGDnxNRQDYsmqK1pSJf4SLpFEy
        Main.jsx:339 sellerToken:  DQdgcsLt1queiXzGaq59yuJZe5dR4ne1L6N5hjErHYwQ
        Main.jsx:340 nftToAccept:  Aw2jJ1fpYHjyBJNaJkRXvsbfmWjCz326Y1H2rEXb5vM9
         */

        let txSig = await program.rpc.acceptBid(
            {
                accounts: {
                    auction: new PublicKey(auctionAccount),
                    authority: walletPubKey,
                    currencyHolderPubkey,
                    nftToAccept: NFT_ADDR,
                    lastBidder: bidderNftHolder,
                    sellerTokenAccount,
                    metadata,
                    auctionOrganizerTreasury: AUCTION_ORGANIZER_TREASURY,
                    sA: _nftHolderPubkey,
                    seller: auctionAccountInfo.seller,
                    // rent: SYSVAR_RENT_PUBKEY,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                },
                remainingAccounts,
                signers: []
            });
        console.log(txSig);
    }

    const buyNftNow = async () => {
        const connection = new Connection(network, preflightCommitment);
        const provider = new anchor.Provider(connection, wallet, preflightCommitment);
        const program = new anchor.Program(idl, new PublicKey(PROGRAM_IDS.auction), provider);

        if (!wallet.connected)
        {
            console.log('Waiting for wallet connection');
            return;
        }

        const walletPubKey = provider.wallet.publicKey;
        const buyer = walletPubKey;
        console.log('walletPubKey: ', walletPubKey.toString());
        console.log('auctionAccount: ', auctionAccount.toString());

        const auctionAccountInfo = await program.account.auction.fetch(auctionAccount.toString());
        console.log('auctionAccountInfo: ', auctionAccountInfo);
        console.log('buyer: ', buyer.toString());
        console.log('auctionAccountInfo.currency_holder_pubkey: ', auctionAccountInfo.currencyHolderPubkey.toString());
        console.log('auctionAccountInfo.nft_holder_pubkey: ', auctionAccountInfo.nftHolderPubkey.toString());
        console.log('auctionAccountInfo Price: ', auctionAccountInfo.price.toString());
        console.log('auctionAccountInfo.seller: ', auctionAccountInfo.seller.toString());
        if (auctionAccountInfo.seller.toString() == walletPubKey.toString())
        {
            console.log('Seller: ', auctionAccountInfo.seller.toString());
            alert('You can not buy your own NFT');
            return;
        }

        let auction = new PublicKey(auctionAccount.toString());

        const [_currencyHolderPubkey,  _currencyHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auction.toBuffer(),
                TREASURY,
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        let currencyHolderPubkey = _currencyHolderPubkey;
        const sellerTokenAccount = (await connection.getTokenLargestAccounts(NFT_ADDR)).value[0].address;

        console.log('auction: ', auction.toString());
        console.log('auction seller: ', auctionAccountInfo.seller.toString());
        console.log('currencyHolderPubkey: ', currencyHolderPubkey.toString());
        console.log('sellerTokenAccount: ', sellerTokenAccount.toString());
        console.log('Buyer: ', buyer.toString());

        const [_buyerNftHolder, _buyerNftHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                buyer.toBuffer(),
                NFT_ADDR.toBuffer(),
                auction.toBuffer(),
                BIDDER_NFT,
                NFT_TREASURY
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );


        let buyerNftHolder = _buyerNftHolder; // auctionAccountInfo.nftHolderPubkey;
        console.log('Buyer nftHolderPubkey: ', buyerNftHolder.toString());

        const sA = (await connection.getTokenLargestAccounts(NFT_ADDR)).value[0].address;

        const [_nftHolderPubkey, _nftHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auction.toBuffer(),
                NFT_TREASURY
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        const metadata = await getMetadata(NFT_ADDR);

        const metadataObj = await program.provider.connection.getAccountInfo(
            metadata,
        );
        const metadataDecoded = decodeMetadata(
            Buffer.from(metadataObj.data),
        );

        console.log('metadataDecoded: ', metadataDecoded);

        const remainingAccounts = [];

        for (let i = 0; i < metadataDecoded.data.creators.length; i++) {
            remainingAccounts.push({
                pubkey: new PublicKey(metadataDecoded.data.creators[i].address),
                isWritable: true,
                isSigner: false,
            });
        }

        let oriRefundReceiver = auctionAccountInfo.refundReceiver;

        console.log('_nftHolderPubkey from seed: ', _nftHolderPubkey.toString());
        console.log('nftHolderPubkey auction: ', auctionAccountInfo.nftHolderPubkey.toString());
        console.log('oriRefundReceiver: ', oriRefundReceiver.toString());

        let txSig = await program.rpc.buyNft(
            _buyerNftHolderBump,
            {
                accounts: {
                    auction,
                    authority: walletPubKey,
                    buyer,
                    currencyHolderPubkey,
                    nftToAccept: NFT_ADDR,
                    buyerNftHolder,
                    oriRefundReceiver,
                    sellerTokenAccount,
                    metadata,
                    sellerToken: NFT_ADDR,
                    auctionOrganizerTreasury: AUCTION_ORGANIZER_TREASURY,
                    sA: _nftHolderPubkey,
                    seller: auctionAccountInfo.seller,
                    rent: SYSVAR_RENT_PUBKEY,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                },
                remainingAccounts,
                signers: []
            });
        console.log(txSig);
    }

    const cancelAuction = async () => {
        const connection = new Connection(network, preflightCommitment);
        const provider = new anchor.Provider(connection, wallet, preflightCommitment);
        const program = new anchor.Program(idl, new PublicKey(PROGRAM_IDS.auction), provider);

        if (!wallet.connected)
        {
            console.log('Waiting for wallet connection');
            return;
        }

        const walletPubKey = provider.wallet.publicKey;
        const seller = walletPubKey;
        console.log('walletPubKey: ', walletPubKey.toString());
        console.log('auctionAccount: ', auctionAccount.toString());

        const auctionAccountInfo = await program.account.auction.fetch(auctionAccount.toString());
        console.log('auctionAccountInfo: ', auctionAccountInfo);
        console.log('auctionAccountInfo.currency_holder_pubkey: ', auctionAccountInfo.currencyHolderPubkey.toString());
        console.log('auctionAccountInfo.nft_holder_pubkey: ', auctionAccountInfo.nftHolderPubkey.toString());
        console.log('auctionAccountInfo Price: ', auctionAccountInfo.price.toString());
        console.log('auctionAccountInfo.seller: ', auctionAccountInfo.seller.toString());
        if (auctionAccountInfo.seller.toString() != walletPubKey.toString())
        {
            console.log('Seller: ', auctionAccountInfo.seller.toString());
            alert('You can not cancel other user\'s NFT');
            return;
        }

        let auction = new PublicKey(auctionAccount.toString());

        const [_currencyHolderPubkey,  _currencyHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auction.toBuffer(),
                TREASURY,
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );

        let currencyHolderPubkey = _currencyHolderPubkey;
        const sellerTokenAccount = (await connection.getTokenLargestAccounts(NFT_ADDR)).value[0].address;

        const [_nftHolderPubkey, _nftHolderBump] = await PublicKey.findProgramAddress(
            [
                PREFIX,
                auction.toBuffer(),
                NFT_TREASURY
            ],
            new PublicKey(PROGRAM_IDS.auction),
        );
        let nftHolderPubkey = _nftHolderPubkey;

        console.log('auction: ', auction.toString());
        console.log('auction seller: ', auctionAccountInfo.seller.toString());
        console.log('currencyHolderPubkey: ', currencyHolderPubkey.toString());
        console.log('sellerTokenAccount: ', sellerTokenAccount.toString());

        const sA = auctionAccountInfo.sellerNftHolder; // (await connection.getTokenLargestAccounts(NFT_ADDR)).value[0].address;

        console.log('_nftHolderPubkey from seed: ', _nftHolderPubkey.toString());
        console.log('nftHolderPubkey auction: ', auctionAccountInfo.nftHolderPubkey.toString());
        console.log('sellerNftHolder auction: ', auctionAccountInfo.sellerNftHolder.toString());
        console.log('sA: ', sA.toString());

        let txSig = await program.rpc.closeAuction(
            auctionAccountInfo.auctionBump,
            {
                accounts: {
                    auction,
                    authority: walletPubKey,
                    seller,
                    oriRefundReceiver: auctionAccountInfo.bidder,
                    nftHolderPubkey,
                    currencyHolderPubkey,
                    nftToAccept: NFT_ADDR,
                    sellerTokenAccount,
                    sellerToken: NFT_ADDR,
                    auctionOrganizerTreasury: AUCTION_ORGANIZER_TREASURY,
                    sA,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                },
                signers: []
            });
        console.log(txSig);
    }

    const updateBidList = async () => {
        const connection = new Connection(network, preflightCommitment);
        try {
            const publicKey = auctionAccount;
            const transSignatures = await connection.getConfirmedSignaturesForAddress2(publicKey, []);
            // console.log('transSignatures: ', transSignatures);
            const transactions = [];

            for (let i = 0; i < transSignatures.length; i++) {
                const signature = transSignatures[i].signature;
                const confirmedTransaction = await connection.getTransaction(signature);
                console.log('confirmedTransaction: ', confirmedTransaction);
                if (confirmedTransaction && i < transSignatures.length - 1) {
                    const { meta, transaction } = confirmedTransaction;

                    if (meta) {
                        const oldBalance = meta.preBalances;
                        const newBalance = meta.postBalances;
                        const amount = oldBalance[0] - newBalance[0];
                        const r = transactions.length - 1;
                        if (r >= 0 && typeof transactions[r].address != 'undefined' && transactions[r].address == transaction.message.accountKeys[0].toBase58())
                        {
                            transactions[r].amount += amount;
                        } else {
                            const transWithSignature = {
                                address: transaction.message.accountKeys[0].toBase58(),
                                signature,
                                fees: meta?.fee,
                                amount: amount,
                            };
                            transactions.push(transWithSignature);
                        }
                    }
                }
            }
            // console.log(transactions);
            console.log(transactions);
            setAuctionTxHistory(transactions);
            return true;
        } catch (err) {
            throw err;
        }
    }

    let start = 0;
    const validateNumberField = e => {
        start = e.target.selectionStart;
        let val = e.target.value;
        val = val.replace(/([^0-9.]+)/, "");
        // val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
        const value = match[1] + match[2];
        e.target.value = value;

        if (val.length > 0) {
            e.target.value = Number(value).toFixed(2);
            e.target.setSelectionRange(start, start);
            // setInitialNftPrice(Number(value).toFixed(2));
        }
    }

    let createAuctionBtn, placeBid, cancelBid, buyNft, acceptBidBtn;
    // Owner of the NFT and auction not initialized
    if (wallet.connected && !!canCreateAuction && !initialized.initialized) {
        createAuctionBtn = <Grid item xs={3}>
            <form onSubmit={createAuction}>
                <input type="text" name="initAuctionPrice" text={0.3}/>
                <button type="submit">Create auction</button>
            </form>
        </Grid>;
    } else if (wallet.connected && !!canAcceptBid) {
        acceptBidBtn = <Grid item xs={12}>
            <Button
                color="primary"
                variant="contained"
                onClick={acceptBid}
                className={classes.button}
            >Accept Last Bid for {lastBidPrice} SOL</Button>&nbsp; or &nbsp;
            <Button
                color="primary"
                variant="contained"
                onClick={cancelAuction}
                className={classes.button}
            >Cancel sale</Button>

        </Grid>;
    } else if (wallet.connected && !!canCreateAuction && initialized.initialized) {
        cancelBid = <Grid item xs={12}>
            <Button
                    color="primary"
                    variant="contained"
                    onClick={cancelAuction}
                    className={classes.button}
            >Cancel sale</Button>
        </Grid>;
    } else if (wallet.connected && !!canBid && !canCreateAuction && initialized.initialized) { // Not owner of the NFT and auction is initialized
        placeBid = <Grid item xs={12}>
            <form onSubmit={submitBid}>
                <input onChange={validateNumberField}  name="bidInputAmount" placeholder="Only numbers" className="auctionInput" />
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.button}
                >Place Bid</Button>&nbsp; or &nbsp;


            </form>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={buyNftNow}
                className={classes.button}
            >Buy now for {nftBuyPrice} SOL</Button>
        </Grid>;
    } else if (wallet.connected && !!canBid && !canCreateAuction && initialized.initialized) { // Not owner of the NFT and auction is initialized
        buyNft = <Grid item xs={12}>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={buyNftNow}
                className={classes.button}
            >Buy now for {nftBuyPrice} SOL</Button>
        </Grid>;
    }

    if (!wallet.connected) {
        return (
            <div>
                <Header />
                <Grid item xs={3}>Connect wallet</Grid>
                <Footer />
            </div>);
    } else if (!loaded) {
        return (
            <div>
                <Header />
                <Grid item xs={3}>Loading...</Grid>
                <Footer />
            </div>);
    }

    return (
        <div>
            <Header />
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box textAlign="center">
                            <Intro
                                image={nftImage}
                                votes={initialized}
                                auctionPubkey={initialized.auctionPubkey}
                                createAuction={createAuction}
                                nftKey={nftKey}
                                canCreateAuction={canCreateAuction}
                            />

                                {acceptBidBtn}
                                {cancelBid}
                                {placeBid}
                                {/* createAuctionBtn */}
                                {buyNft}
                            </Box>
                        </Grid>



                    </Grid>
                    <Grid item xs={12}>
                        <VoteHistory voteTxHistory={auctionTxHistory} />
                    </Grid>
                </Container>
            <Footer />
        </div>
    );
}

/*

<VoteOption side="Try This" handleVote={() => tryThis(
                    'EKjktXRbeuay6w9EXNHf2Ap4Kx1VeJpkCtJb3oZ3JNTv',
                    'DU52eqb2k6BoHyzGRB1tPUV7VVkUGPQvYXJufp3LB7Bw',
                    0.11 * LAMPORTS_PER_SOL)} />
Localnet

Auction id:  BKka8Kw22yRLzsvycYSBd1DafGXhmRdNn7LEBSs4xzuh
Main.jsx:981 PDA:  AhJcw5Y62wkeCUqrqYuUVD8HhaAZ1ZpNDfAB5ambtHTo
Main.jsx:1487 p d a: a  AhJcw5Y62wkeCUqrqYuUVD8HhaAZ1ZpNDfAB5ambtHTo
Main.jsx:1002 currencyHolderPubkey:  4wGxpqQ7uu7wj544bLG11uwWrbPNrQnSYHKnZHWhj3Yg
Main.jsx:1003 currencyHolderAuthority:  AhJcw5Y62wkeCUqrqYuUVD8HhaAZ1ZpNDfAB5ambtHTo
Main.jsx:853 Create MINT
Main.jsx:1008 itemPubkey:  8eZhAePPj3PY2MR58gztwwuKrSJ2rXn9gcND1hwXkk4K
Main.jsx:1012 itemHolderPubkey:  2MSLUPW8jbawV824HTubR5KyuhPgLEyRJAe4gk8vHNG7
Main.jsx:1042 PDA:  AhJcw5Y62wkeCUqrqYuUVD8HhaAZ1ZpNDfAB5ambtHTo
Main.jsx:1064 G:  2rwmMgHtMCt9Qe26jWQaFUK3xnJk3XK1nCvuYvft6Dk99EquVmd8Ek6rdxc9GHt5HhjPD4BCEjUf5t5fsRstKAaC

devnet

Auction id:  9nUZup4pff55edUMwmvKBPAXEPRgSLwMs5Y88zgeQtBX
Main.jsx:966 PDA:  AhJcw5Y62wkeCUqrqYuUVD8HhaAZ1ZpNDfAB5ambtHTo
Main.jsx:838 Create MINT
Main.jsx:988 itemPubkey:  AUTKv8KQUBf5uYZKuBFH5ZzFj63CH8LuVP98xnkvJua1
Main.jsx:992 itemHolderPubkey:  Au8AKWdwtzqFAz7C5QrC77nj7zXGRz3SzA114xnkFS3T
Main.jsx:1015 currencyHolderPubkey:  GpppmCAN5FdUNkxfLPDEcxG1xy6r9yrZgpCiv5KnZgef
Main.jsx:1021 PDA:  AhJcw5Y62wkeCUqrqYuUVD8HhaAZ1ZpNDfAB5ambtHTo

 */

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;