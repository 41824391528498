// #!/usr/bin/node

// Used code from https://github.com/21e8/sol-nft-tools

import base58 from "bs58";
import { deserializeUnchecked, BinaryReader, BinaryWriter } from "borsh";
import { PublicKey } from "@solana/web3.js";
import * as anchor from"@project-serum/anchor";
import Enum from 'enum';
import axios from 'axios';
import { CURL_PATH } from "./config";

const METADATA_PREFIX = "metadata";
class Creator {
    address;
    verified;
    share;

    constructor(args) {
        this.address = args.address;
        this.verified = args.verified;
        this.share = args.share;
    }
}

const MetadataKey = new Enum({
    Uninitialized: 0,
    MetadataV1: 4,
    EditionV1: 1,
    MasterEditionV1: 2,
    MasterEditionV2: 6,
    EditionMarker: 7,
});

class Data {
    name;
    symbol;
    uri;
    sellerFeeBasisPoints;
    creators;
    constructor(args) {
        this.name = args.name;
        this.symbol = args.symbol;
        this.uri = args.uri;
        this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
        this.creators = args.creators;
    }
}

class Metadata {
    key;
    updateAuthority;
    mint;
    data;
    primarySaleHappened;
    isMutable;
    masterEdition;
    edition;
    constructor(args) {
        this.key = MetadataKey.MetadataV1;
        this.updateAuthority = args.updateAuthority;
        this.mint = args.mint;
        this.data = args.data;
        this.primarySaleHappened = args.primarySaleHappened;
        this.isMutable = args.isMutable;
    }
}

const PubKeysInternedMap = new Map();

const toPublicKey = (key) => {
    if (typeof key !== "string") {
        return key;
    }

    let result = PubKeysInternedMap.get(key);
    if (!result) {
        result = new PublicKey(key);
        PubKeysInternedMap.set(key, result);
    }

    return result;
};

const TOKEN_PROGRAM_ID = new PublicKey(
    "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

const BPF_UPGRADE_LOADER_ID = new PublicKey(
    "BPFLoaderUpgradeab1e11111111111111111111111"
);

const MEMO_ID = new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr");

const METADATA_PROGRAM_ID =
    "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s";

const VAULT_ID =
    "vau1zxA2LbssAUEF7Gpw91zMM1LvXrvpzJtmZ58rPsn";

const AUCTION_ID =
    "auctxRXPeJoc4817jDhf4HbjnhEcr1cCXenosMhK5R8";

const METAPLEX_ID =
    "p1exdMJcjVao65QdewkaZRUnU6VPSXhus9n2GzWfh98";

const SYSTEM = new PublicKey("11111111111111111111111111111111");

const METADATA_SCHEMA = new Map([
    [
        Data,
        {
            kind: "struct",
            fields: [
                ["name", "string"],
                ["symbol", "string"],
                ["uri", "string"],
                ["sellerFeeBasisPoints", "u16"],
                ["creators", { kind: "option", type: [Creator] }],
            ],
        },
    ],
    [
        Creator,
        {
            kind: "struct",
            fields: [
                ["address", [32]],
                ["verified", "u8"],
                ["share", "u8"],
            ],
        },
    ],
    [
        Metadata,
        {
            kind: "struct",
            fields: [
                ["key", "u8"],
                ["updateAuthority", [32]],
                ["mint", [32]],
                ["data", Data],
                ["primarySaleHappened", "u8"],
                ["isMutable", "u8"],
            ],
        },
    ],
]);

const findProgramAddress = async (
    seeds,
    programId
) => {
    const key =
        "pda-" +
        seeds.reduce((agg, item) => agg + item.toString("hex"), "") +
        programId.toString();

    const result = await PublicKey.findProgramAddress(seeds, programId);

    return [result[0].toBase58(), result[1]];
};

const programIds = () => {
    return {
        token: TOKEN_PROGRAM_ID,
        associatedToken: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
        bpf_upgrade_loader: BPF_UPGRADE_LOADER_ID,
        system: SYSTEM,
        metadata: METADATA_PROGRAM_ID,
        memo: MEMO_ID,
        vault: VAULT_ID,
        auction: AUCTION_ID,
        metaplex: METAPLEX_ID,
    };
};

const decodeMetadata = (buffer) => {
    const metadata = deserializeUnchecked(
        METADATA_SCHEMA,
        Metadata,
        buffer
    );

    metadata.data.name = metadata.data.name.replace(/\0/g, "");
    metadata.data.symbol = metadata.data.symbol.replace(/\0/g, "");
    metadata.data.uri = metadata.data.uri.replace(/\0/g, "");
    metadata.data.name = metadata.data.name.replace(/\0/g, "");
    return metadata;
};

const extendBorsh = () => {
    (BinaryReader.prototype).readPubkey = function () {
        const reader = BinaryReader;
        const array = reader.readFixedArray(32);
        return new PublicKey(array);
    };

    (BinaryWriter.prototype).writePubkey = function (value) {
        const writer = BinaryWriter;
        writer.writeFixedArray(value.toBuffer());
    };

    (BinaryReader.prototype).readPubkeyAsString = function () {
        const reader = BinaryReader;
        const array = reader.readFixedArray(32);
        return base58.encode(array);
    };

    (BinaryWriter.prototype).writePubkeyAsString = function (
        value
    ) {
        const writer = BinaryWriter;
        writer.writeFixedArray(base58.decode(value));
    };
};

extendBorsh();

async function getMetadata(pubkey, url) {
    let metadata;

    try {
        const metadataPromise = await fetchMetadataFromPDA(pubkey, url)

        if (metadataPromise && metadataPromise.data.length > 0) {
            metadata = decodeMetadata(metadataPromise.data);
        }
    } catch (e) {
        console.log(e);
    }

    return metadata;
}

async function getMetadataKey(
    tokenMint
) {
    const PROGRAM_IDS = programIds();

    return (
        await findProgramAddress(
            [
                Buffer.from(METADATA_PREFIX),
                toPublicKey(PROGRAM_IDS.metadata).toBuffer(),
                toPublicKey(tokenMint).toBuffer(),
            ],
            toPublicKey(PROGRAM_IDS.metadata)
        )
    )[0];
}

async function fetchMetadataFromPDA(pubkey, url) {
    let metadataInfo;
    try {
        const connection = new anchor.web3.Connection(url, { disableRetryOnRateLimit: true });
        const metadataKey = await getMetadataKey(pubkey.toBase58());
        metadataInfo = await connection.getAccountInfo(
            toPublicKey(metadataKey)
        );
    } catch (error) {
        console.log(' Error - Can\'t connect to public address');
    }

    return metadataInfo;
}

let mints = [];

const createJsonObject = async (url, key) =>
{
    let key1 = new anchor.web3.PublicKey(key) || null;

    const tokenMetadata = await getMetadata(key1, url);
    if (tokenMetadata === undefined) {
        mints.push({'error': ' Wrong mint address set as completed = -1'});
    } else {
        // const arweaveData = await axios.get(tokenMetadata.data.uri,  function (req, res) {
        //     res.header("Access-Control-Allow-Origin", "*",
        //     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',);
        // });
        let header = new Headers({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        });

        try {
            const arweaveData = await axios.get(CURL_PATH + "?url=" + tokenMetadata.data.uri, {
                crossdomain: true
            });
            console.log('arweaveData: https://fbm.timonix.com/curl/curl.php?url=', arweaveData);
            return {
                tokenData: {
                    ...tokenMetadata.data,
                    creators: tokenMetadata.data.creators.map((d) => {
                        return {
                            share: d.share,
                            address: new PublicKey(d.address).toBase58(),
                            verified: !!d.verified,
                        };
                    }),
                    updateAuthority: new PublicKey(tokenMetadata.updateAuthority).toBase58(),
                    primarySaleHappened: tokenMetadata.primarySaleHappened,
                    isMutable: tokenMetadata.isMutable,
                },
                metadata: arweaveData.data,
                mint: key,
            };
        } catch (err) {
            console.log(err);
        }



        // tokenMetadata.data.creators.map((dat) => {
        //     // console.log('updateAuthority dat: ', dat);
        // });

        return true;
    }

    return await new Promise((resolve) => {
        setTimeout(() => {
            resolve(undefined);
        }, 150);
    });
};

export const getMeta = async (token, url) => {
    const x = await createJsonObject(url, token);
    console.log('Mints: ', x);
    return x;
};

