import React, {useState} from "react";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";
import { useWallet } from "@solana/wallet-adapter-react";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    "&.hidden": {
      visibility: "hidden",
    },
  },
  connected: {
    color: green[500],
  },
  connectedBubble: {
    backgroundColor: green[500],
    height: 12,
    width: 12,
    borderRadius: "50%",
    marginRight: theme.spacing(0.5),
  },
  title: {
    fontWeight: 700,
  },
}));

export default function Intro({
  image,
  votes,
  auctionPubkey,
  createAuction,
  nftKey,
  canCreateAuction
}) {
  const wallet = useWallet();
  const classes = useStyles();
  const [listButtonDisabled, setListButtonDisabled] = useState(true);
  const [initialNftPrice, setInitialNftPrice] = useState(0);

  let start = 0;
  const validateNumberField = e => {
    start = e.target.selectionStart;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, "");
    // val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];
    e.target.value = value;
    setInitialNftPrice(value);

    if (val.length > 0) {
      e.target.value = Number(value).toFixed(2);
      e.target.setSelectionRange(start, start);
      setInitialNftPrice(Number(value).toFixed(2));
      if (Number(value).toFixed(2) > 0.00)
      {
        setListButtonDisabled(false);
      } else {
        setListButtonDisabled(true);
      }
    } else {
      setInitialNftPrice(0);
      setListButtonDisabled(true);
    }
  }

  if (!votes.initialized) {
    // return (<Box textAlign="center"></Box>);
  }

  return (
    <Box textAlign="center">
      <Typography
        component="h1"
        variant="h3"
        gutterBottom
        className={classes.title}
      >
        List item
      </Typography>
      <Typography variant="body1">
        <img src={image} alt="img" width={300}/>
        <br />Mint: {nftKey}
      </Typography>

      <Box marginTop="8px">
        {wallet.connected ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="body1" className={classes.connected}>
              {auctionPubkey ? 'Listed in auction: ' + auctionPubkey : ''}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1">
            To get started, connect your wallet below:
          </Typography>
        )}
        <WalletMultiButton
          className={
            wallet.connected
              ? [classes.button, "hidden"].join(" ")
              : classes.button
          }
        />
      </Box>
      {!votes.initialized && canCreateAuction && wallet.connected && (
          <Box marginTop="8px">
            <form>
            <input onChange={validateNumberField} placeholder="Only numbers" className="auctionInput" />
            <Button disabled={listButtonDisabled}
              color="primary"
              variant="contained"
              onClick={() => createAuction(initialNftPrice)}
              className={classes.button}
            >
              List item
            </Button></form>
          </Box>
        )}
    </Box>
  );
}
