// #!/usr/bin/node
import axios from 'axios';

const getTokenHolder = async (url, key) => {
  let holders = [];
  let ax = {};
  ax = await axios.post(url, {
        "jsonrpc": "2.0",
        "id": 1,
        "method": "getProgramAccounts",
        "params": [
            "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",
            {
                "encoding" : "jsonParsed",
                "filters" : [
                    { "dataSize" :165 },
                    { "memcmp" : { "offset" : 0, "bytes" : `${key}` }}
                ]
            }
        ]
  });

  try {
    ax.data.result.forEach((r) => {
      if (r.account.data.parsed.info.tokenAmount.uiAmount > 0) {
        if (!holders[r.account.data.parsed.info.owner]) {
          holders[r.account.data.parsed.info.owner] = {
            amount: 1,
            mints: [key],
          };
        } else {
          holders[r.account.data.parsed.info.owner].amount += 1;
          holders[r.account.data.parsed.info.owner].mints.push(key);
        }
      }
    });
  } catch (error) {
    console.log(' Can\'t get owner ID');
  }
  return holders;
};

export const getHolders = async (mintIds, url) => {
  return await getTokenHolder(url, mintIds);
};

// exports.getHolders = getHolders;