import React from "react";
import { Box, Link, List, ListItem, Typography } from "@material-ui/core";
import {LAMPORTS_PER_SOL} from "@solana/web3.js";

// If this user has voted during this session, show them their transaction history
export default function VoteHistory({ voteTxHistory }) {

  if (voteTxHistory.length < 1) {
    return <Box />;
  }

  const getD = (txID) => {
      return `https://explorer.solana.com/tx/` + txID.signature + `?cluster=devnet`;
  }

  return (
    <Box textAlign="center" marginTop="16px">
      <Typography variant="h4">Auction bids!</Typography>
      <Typography variant="body1">
        Thanks for making an offer.
      </Typography>
      <Typography variant="body1">
        Check out your recorded {voteTxHistory.length === 1 ? "bid" : "bids"}{" "}
        on the Solana blockchain:
      </Typography>
      <List>
          <ListItem key='head1' style={{ justifyContent: "center" }}>
              <Typography>Account</Typography>
              <Typography>Transaction link</Typography>
              <Typography>Amount</Typography>
          </ListItem>

        {voteTxHistory.map((txID, i) => (
          <ListItem key={i} style={{ justifyContent: "center" }}>
            <Typography>{`Account ${txID.address} ${i}`} | </Typography>
            <Link
              href={getD(txID)}
              underline="always"
            >{` | Transaction link | `} </Link>
              <Typography> | {`Amount ${(txID.amount/LAMPORTS_PER_SOL).toFixed(2)}`}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
