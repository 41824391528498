import * as anchor from '@project-serum/anchor';
import {PublicKey} from "@solana/web3.js";

export const capitalize = (string: String) => {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const percentize = (decimal: number, min: number, max: number) => {
  if (typeof decimal !== "number" || isNaN(decimal)) return "";
  return max === 0
    ? `${(decimal * 100).toFixed(0)}%`
    : `${(decimal * 100).toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: min || 2,
        maximumFractionDigits: max || 2,
      })}%`;
};

const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
    'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s',
);

export const formatWithCommas = (number: number) =>
  typeof number === "number" ? number.toLocaleString("en-US") : number;

export const getMetadata = async (
    mint: anchor.web3.PublicKey,
): Promise<anchor.web3.PublicKey> => {
    return (
        await anchor.web3.PublicKey.findProgramAddress(
            [
                Buffer.from('metadata'),
                TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                mint.toBuffer(),
            ],
            TOKEN_METADATA_PROGRAM_ID,
        )
    )[0];
};