function Footer() {
    return (
        <div className="wrap footer">
            <div className="footer_links">
                <a href="#" className="f_logo">
                    <img className="dark_logo" src="/img/logo_dark.svg" alt="img" />
                    <img className="light_logo" src="/img/logo_light.svg" alt="img" />
                </a>
                <a href="#" className="reg"><img src="/img/FAQ.svg" alt="img" /> FAQ</a>
                <a href="#" className="reg"><img src="/img/twitter.svg" alt="img" /> Twitter</a>
                <a href="#" className="reg"><img src="/img/discord.svg" alt="img" /> Discord</a>
            </div>
            <div className="copyright">
                &copy; 2021 HowRare.is All rights reserved
            </div>
        </div>
        );
    }

export default Footer;